<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <h4 class="ml-1 mb-2">
              Hasil Pengecekan
            </h4>
            <b-col cols="12">
              <label>Kendaraan:</label>
              <p>{{ model.vehicle?model.vehicle.number:'' }}</p>
              <label>Tanggal Check:</label>
              <p>{{ model.start | moment_full }}</p>
              <label>Petugas:</label>
              <p>{{ model.author?model.author.name:'' }}</p>
            </b-col>
          </b-row>
          
          <b-tabs>
            <b-tab active>
              <template #title>
                Kelengkapan Kendaraan
              </template>
        
              <b-table 
                striped
                small
                responsive
                :fields="fields"
                :items="model.property_details"
              >
            
                <template #cell(is_exist)="data">

                  <b-form-checkbox 
                    v-model="data.item.pivot.is_exist"
                    class="custom-control-primary"
                    name="check-button"
                    disabled
                  />
                </template>
                <template #cell(is_work)="data">

                  <b-form-checkbox 
                    v-model="data.item.pivot.is_work"
                    class="custom-control-primary"
                    name="check-button"
                    disabled
                  />
                </template>
                <template #cell(is_good)="data">

                  <b-form-checkbox 
                    v-model="data.item.pivot.is_good"
                    class="custom-control-primary"
                    name="check-button"
                    disabled
                  />
                </template>
              </b-table>
            </b-tab>
            <b-tab>
              <template #title>
                Body Kendaraan
              </template>
        
              <b-table 
                striped
                small
                responsive
                :fields="fields"
                :items="model.body_details"
              >
            
                <template #cell(is_exist)="data">

                  <b-form-checkbox 
                    v-model="data.item.pivot.is_exist"
                    class="custom-control-primary"
                    name="check-button"
                    disabled
                  />
                </template>
                <template #cell(is_work)="data">

                  <b-form-checkbox 
                    v-model="data.item.pivot.is_work"
                    class="custom-control-primary"
                    name="check-button"
                    disabled
                  />
                </template>
                <template #cell(is_good)="data">

                  <b-form-checkbox 
                    v-model="data.item.pivot.is_good"
                    class="custom-control-primary"
                    name="check-button"
                    disabled
                  />
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BTable, BRow, BCol, BFormCheckbox, BButton,BSpinner,BCard,BTabs, BTab} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import FormLabel from '@/views/base/form/FormLabel.vue'
import moment from "moment"


export default {
  components: {
    BFormCheckbox,
    BTable,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    FormLabel,
    BTabs, BTab,
  },
  data() {
    return {
      fields:[{
        key:'name',
        label:'Keterangan'
      },{
        key:'is_exist',
        label:'Ada'
      },{
        key:'is_good',
        label:'Baik'
      },{
        key:'is_work',
        label:'Berfungsi'
      }],
      loading :false,
      model:{},
      baseroute:'check',
      posturl:'/check',
      tombol_hide:true,
      ajukan:'',
      renderComp:true,
      title_modal:null,
      data_ajukan:[],
      data_item:{},
      data_time_line:{}
    }
  },
  beforeMount(){
    this.$http.get('/staff').then(ref=>{
        this.data_ajukan = ref.data.data
    })
  },
  mounted(){
    this.getData()
  },
  methods: {
     getData(){
        this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
          this.model = res.data
          this.data_time_line = res.data.tracks
        })
     },
    editData(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.id} })
    },
    tanggal(date){
        return moment(date).local('id').format('DD-MM-YYYY')
      },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.contract_subject != null ? 'Anda Ingin Menghapus Data '+data.contract_subject +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.posturl+'/'+data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })

            })
            .catch(err=>{
              this.errorSubmit(err)
            })
          }
        })
    },
   
    cancel(){
        this.$router.push({ name:this.$route.meta.pageActive })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide()
      this.$refs['my-modal-terima'].hide()
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  },
}
</script>
